<script>
import Create1 from "./_Create1.vue";
import Create2 from "./_Create2.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Create1,
    Create2
  },
  methods: {
  }
};
</script>

<template>
  <div class="acitivity-item d-flex">
    <div class="flex-shrink-0">
      <div class="avatar-xs">
        <div class="avatar-title rounded-circle bg-soft-primary text-primary"><i class="ri-add-line"></i></div>
      </div>
    </div>
    <div class="flex-grow-1 ms-2 mb-5">
      <h6 class="mb-1">Create new post</h6>
      <p class="text-muted mb-2">Choose how you want to create your audio content: record on your own or collaborate with others in a shared audio room.</p>

      <div class="mt-3">
        <button type="button" class="btn btn-danger btn-label waves-effect waves-light" data-bs-toggle="modal" data-bs-target=".recorderModal1">
          <i class="ri-checkbox-blank-circle-fill label-icon align-middle fs-16 me-2"></i>
          &nbsp;REC
        </button>
        <router-link :to="{ name: 'live-create', params: { id: this.$route.params.id } }" class="btn btn-dark btn-label waves-effect waves-light ms-2">
          <i class="ri-broadcast-line label-icon align-middle fs-16 me-2"></i>
          &nbsp;LIVE
        </router-link>
      </div>

      <Create1></Create1>
      <Create2></Create2>

    </div>
  </div>
</template>
